import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

// Styles
import "./skodel-services.css";

// Components
import IndexLayout from "../layouts";
import Page from "../components/Page";
import StackedImage from "../components/StackedImage";
import FAQ from "../components/FAQ";
import BookDiscussion from "../components/BookDiscussion";

// Images and icons
import ServiceHero from "../assets/images/response-hero.jpg";
import ServiceCardBG from "../assets/images/leading-card-bg.png";
import IconMessageWhite from "../assets/icons/icon-message-white.svg";
import IconMessageDark from "../assets/icons/icon-message-dark.svg";
import IconSettingWhite from "../assets/icons/icon-setting-white.svg";
import IconSettingDark from "../assets/icons/icon-setting-dark.svg";
import IconGlobeWhite from "../assets/icons/icon-globe-white.svg";
import IconGlobeDark from "../assets/icons/icon-globe-dark.svg";
import ResponseEcoSystem from "../assets/images/response-eco-system.png";
import ResponseConnectPeople from "../assets/images/response-connect-people.jpg";
import ResponseAutomaticFlag from "../assets/images/response-automatic-flag.jpg";
import ResponseAutomaticFlagIcon from "../assets/images/response-automatic-flag-icon.png";
import ResponseAutomaticFlagChart from "../assets/images/response-automatic-flag-chart.png";
import ResponseCentralPlace from "../assets/images/response-central-place.png";
import ResponseStrategicAction from "../assets/images/response-strategic-action.jpg";
import ServiceStackedImage from "../assets/images/response-mental-health.jpg";

// @ts-ignore
const NavLink = (props: any) => <Link activeClassName="active" {...props} />;

const ServicesValueData = [
  {
    id: "SV0001",
    imageWhite: IconMessageWhite,
    imageDark: IconMessageDark,
    title: "Encourages silence on important issues",
    description:
      "If people don't feel adequate support is in place they're more likely to hide important issues from leaders. This could hide potential systemic issues or data that could inform better decisions from leadership teams.",
  },
  {
    id: "SV0002",
    imageWhite: IconSettingWhite,
    imageDark: IconSettingDark,
    title: "Encourages external resolution of internal matters",
    description:
      "If issues occur inside an organisation and people don't see adequate internal support, they seek external resolution. This may involve legal action or sharing information socially that can impact reputation.",
  },
  {
    id: "SV0003",
    imageWhite: IconGlobeWhite,
    imageDark: IconGlobeDark,
    title: "Leaders can't document internal support provided",
    description:
      "If mental health concerns aren't responded to, leaders can't document support provided. This is important information to document in case situations escalate to an external resolution process.",
  },
];

const FAQData = [
  {
    id: "FAQ0001",
    question: "Can we add our own resources?",
    answer: "Yes, we can work with you to add your own resources.",
  },
  {
    id: "FAQ0002",
    question: "Can leaders respond/communicate inside Skodel?",
    answer:
      "Yes, leaders can react and leave comments on check-ins/survey responses as well as incidents reported by users.",
  },
  {
    id: "FAQ0003",
    question: "How do we use and respond to the data?",
    answer: (
      <>
        Begin by defining what metrics are important to you from a wellbeing
        perspective and design the{" "}
        <a
          href="https://help.skodel.com/kb/guide/en/how-to-create-a-great-check-in-experience-on-skodel-c90y9Ta8BD/Steps/2778134"
          target="_blank"
        >
          right check-in
        </a>{" "}
        for you to collect meaningful data. From there, we recommend running
        post check-in action plans,{" "}
        <a
          href="https://help.skodel.com/kb/guide/en/how-to-use-skodels-data-EntPX3i1Jl/Steps/2778103"
          target="_blank"
        >
          click here
        </a>{" "}
        for guidance on this.
      </>
    ),
  },
  {
    id: "FAQ0004",
    question:
      "What happens if someone discloses a psychological risk on Skodel?",
    answer:
      "The aim with Skodel is to embed your policies and procedures to be triggered to users if a risk is reported. This ensures sensitive issues are always safely responded to. Doing this digitally means leaders maintain an audit trail of issues reported and responses and support provided.",
  },
];

const SkodelSupport = () => {
  const [servicesValue, setServicesValue] = useState<
    {
      id: string;
      imageWhite: string;
      imageDark: string;
      title: string;
      description: string;
    }[]
  >([]);
  const [faq, setFAQ] = useState<
    { id: string; question: string; answer: string | React.ReactNode }[]
  >([]);

  useEffect(() => {
    setServicesValue(ServicesValueData);
    setFAQ(FAQData);
  }, [setServicesValue, setFAQ]);

  return (
    <IndexLayout withBlackLogo={true} workplacesHeader={false}>
      <Helmet>
        <title>Skodel</title>
        <meta
          property="og:title"
          content="Skodel - Report on psychological safety and support your people"
        />
        <meta
          property="og:description"
          content="Skodel's wellbeing and safety platform helps leaders with a higher duty of care meet requirements and support their people."
        />
      </Helmet>
      <Page>
        <section className="section-service-hero">
          <div
            className="section-bg"
            style={{ backgroundImage: `url('${ServiceHero}')` }}
          ></div>
          <div className="main-container">
            <div className="service-hero-content">
              <h1>Skodel Support</h1>
              <p>
                Host mental health support lines, policies and procedures in a
                central place and trigger relevant support to people based on
                the challenges they're facing.
              </p>
              <div className="service-hero-button">
                <NavLink
                  to={"/request-a-demo"}
                  className="btn btn-white btn-hero-cta"
                >
                  Book A Demo
                </NavLink>
                <a
                  href="https://skodel.notion.site/Skodel-Support-5e6dbd80b5ad44a28fa33230648fa743?pvs=4"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-white btn-hero-cta"
                >
                  Find Out More
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section-service-middle">
          <div className="main-container">
            <div className="service-content-wrapper">
              <div className="service-value">
                <div className="section-title text-center">
                  <h2>The problem</h2>
                  <p>
                    Leaders lack resources and certainty on how to safely
                    support sensitive mental health and wellbeing concerns at
                    scale
                  </p>
                </div>
                <div className="service-value-grid column-3">
                  {servicesValue?.map((item, index) => (
                    <div className="service-card" key={item?.id + "-" + index}>
                      <img src={ServiceCardBG} alt={"Skodel"} />
                      <div className="service-icon">
                        <img src={item?.imageWhite} alt={"Skodel"} />
                        <img src={item?.imageDark} alt={"Skodel"} />
                      </div>
                      <div className="service-description">
                        <h3>{item?.title}</h3>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="service-zigzag-content">
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={ResponseEcoSystem} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>
                      An eco-system of support, ready for sensitive concerns
                    </h3>
                    <p>
                      Host your policies, procedures and support lines inside
                      Skodel and trigger relevant support based on check-in
                      data. This enables organisations to confidently uncover
                      even the most sensitive of mental health data with an
                      appropriate response always in place. If someone is being
                      bullied, they can express this on Skodel and get linked to
                      your bullying policy and procedures. Enhances the
                      probability of resolving sensitive issues in a way that
                      aligns with both the person's needs and your
                      organisation's standards.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={ResponseConnectPeople} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>Connect people to mental health support</h3>
                    <p>
                      Mental health data collection may potentially uncover
                      distressing or sensitive information. Leaders shouldn't
                      have to avoid it because of this. Having a response system
                      built in that links people to relevant support lines
                      ensures that appropriate measures are in place to respond
                      to such situations promptly and effectively. It enables
                      organisations to collect important mental health data
                      whilst facilitating appropriate intervention or referrals
                      to mental health professionals if required.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper response-automatic">
                  <div className="service-zigzag-image">
                    <img src={ResponseAutomaticFlag} alt={"Skodel"} />
                    <div className="response-automatic-flag">
                      <img src={ResponseAutomaticFlagIcon} alt={"Skodel"} />
                      <img src={ResponseAutomaticFlagChart} alt={"Skodel"} />
                    </div>
                  </div>
                  <div className="service-zigzag-description">
                    <h3>Automatic flag, alert and response system</h3>
                    <p>
                      Enhance your documentation and communication of mental
                      health matters with an effective flag, alert and response
                      system. Define high signal data and route these alerts to
                      the right people so they can be aware of the information
                      they want to know about. Embed a response system that
                      links people with the right next steps so everyone is
                      aware of the actions they can take to resolve the
                      challenging situations they might face. Carrying out the
                      collection and response of mental health data online
                      streamlines your documentation processes on important
                      issues.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={ResponseCentralPlace} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>A central place for policies &amp; procedures</h3>
                    <p>
                      Drive awareness of your policies and procedures by hosting
                      them centrally on Skodel and targeting them based on
                      relevant data. Add PDFs or links inside an online library
                      and setup triggers for specific data parameters. Increase
                      the likelihood of resolving matters internally by making
                      people aware of the processes they can follow to resolve
                      the situations they're facing.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={ResponseStrategicAction} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>Strategic action plans for leaders</h3>
                    <p>
                      Skodel partners with experts in governance, compliance,
                      mental health and organisational culture to support your
                      leaders in building out strategic action plans. Working
                      with Skodel's data, these plans are purpose built to
                      control risks that might exist in your organisation and
                      the impact of these plans can be reported on over time
                      alongside key metrics identified in the planning phase.
                      These plans help leaders create an environment of
                      psychological safety for wellbeing to thrive, unlocking
                      the potential of all individuals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="stacked-content">
                <StackedImage
                  image={ServiceStackedImage}
                  title={"The confidence to address mental health"}
                  description={
                    "Skodel gives leaders confidence by providing their organisation with an ecosystem of support across all levels. Many mental health issues go unresolved not due to a lack of compassion but rather no response system in place to confidently address serious issues. This is where Skodel helps organisations. It doesn't just provide easy pathways to express concerns but it ensures next steps are always in place for individuals, leaders and the organisation. With Skodel, you are ready to meet mental health head on."
                  }
                />
              </div>
              <div className="service-faq-wrapper">
                <div className="section-title text-center">
                  <h2>Frequently asked questions</h2>
                </div>
                <div className="service-faq-content">
                  <div className="service-faq-list">
                    {faq?.map((item, index) => (
                      <FAQ
                        key={item?.id + "-" + index}
                        question={item?.question}
                        answer={item?.answer}
                      />
                    ))}
                  </div>
                  <BookDiscussion
                    title={"Book in a discussion"}
                    link={"/request-a-demo"}
                    button={"Request A Demo"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Page>
    </IndexLayout>
  );
};

export default SkodelSupport;
